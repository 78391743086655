import React from 'react';
import { Snackbar, Alert } from "@mui/material";

export default function CustomerAlert(props){    
   return(
        <Snackbar
            open={props.openSnack}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={props.snackClose}
        >
            <Alert
                onClose={props.snackClose}
                severity="warning"
                sx={{ width: "100%" }}
            >
                 {props.alertMsg}
            </Alert>
        </Snackbar>
  )
}