import { pink, red, orange, blue, green, grey } from "@material-ui/core/colors";
export const palette = {
    primary : {
        contrastText: '#FFF',
        light: '#E1F1FF',
        main: '#337AB7',
        dark: '#458bcd',
        muted: '#a9c6e0',        
    },
    secondary : {
        contrastText: '#FFF',
        light: '#f18a3e',
        main: '#FF5700',
        dark: orange[700],
    },
    error : {
        contrastText: '#FFF',
        light: '#ffe0e0',
        main: '#84291f',
        dark: '#84291f',
    },
    warning : {
        contrastText: '#FFF',
        light: '#f18a3e',
        main: '#FF5700',
        dark: orange[700],
    },
    info : {
        contrastText: '#000',
        light: '#ffffff',
        main: '#212529',
        dark: blue[700],
    },
    success : {
        contrastText: '#FFF',
        light: '#d0f6eb',
        main: '#65CCB4',
        dark: green[700],
    },
    dark : {
        contrastText: '#FFF',
        light: grey[700],
        main: grey[500],
        dark: grey[100],
    },
    type : 'light',
    contrastThreshold : 3,
    tonalOffset : 0.2,
    greys: {
        white: '#ffffff',
        light: '#E5E5E5',
        muted: '#6c757d',
        medium: '#212529',
        dark: '#1f1f1f',
        black: '#000000',
    },
    // The color used to divide different elements.
    divider: 'rgba(0, 0, 0, 0.12)',
    // The background colors used to style the surfaces.
    // Consistency between these values is important.
    background: {
        paper: '#ffffff',
        default: grey[50],
    },
};
