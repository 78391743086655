import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const ImageCarousel = props => {

    const [count, setCount] = useState(1);

    return (
        <>
        {props.show && <p style={{ textAlign: 'center' }}>{count} of {props.numOfImages}</p>}
        <div style={{padding: '2% 0'}}>
            {props.show && 
                <Carousel
                    NextIcon={<NavigateNextIcon/>}
                    PrevIcon={<NavigateBeforeIcon/>}
                    //autoPlay={true}
                    animation='fade'
                    interval={4000}
                    reverseEdgeAnimationDirection={false}
                    navButtonsAlwaysVisible={true}
                    fullHeightHover={false}
                    navButtonsProps={{          //THIS STYLES BOTH BUTTONS
                        style: {
                            backgroundColor: '#337AB7',
                            //borderRadius: '0 50% 50% 0',
                            margin: '0px',
                        }
                    }} 
                    indicatorIconButtonProps={{
                        style: {
                            color: '#a9c6e0'
                        }
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: '#337AB7'
                        }
                    }}
                    onChange={(index, active) => setCount(index+1)}
                >
                    {props.children}
                </Carousel>
            }
            {!props.show &&
                <p>No images to show</p>
            }
        </div>
        </>
    );

};



export default ImageCarousel;