export function getStatusString(status) {
  var res = "";
  switch(status) {
      case 2:
          res = "Avail for Dispatch";
          break;
      case 3:
          res = "Driver PENDING";
          break;                   
      case 4:
          res = "Driver Responsibility";
          break;
      case 8:  
            res = "Delivered";
            break;
      case 16:
          res = "Verified";
          break;
      case 32:
          res = "Invoiced";                  
          break;
      default:
          break;                
  } 
  return res;
}

export function getLocationString (location) {
    var res = "";
    switch(location) {
        case 1:
            res = "At Client";
            break;
        case 2:
            res = "On Driver";
            break;  
        case 4:
            res = "In Sort";
            break;
        case 5:
            res = "Rotation Sort";
            break;  
        case 6:
            res = "Mass Sort";
            break;                                                           
        case 8:
            res="Delivered";
            break;  
        case 16:
            res="MIA";
            break;                                 
        default:
            break;                
    } 
    return res;
}


export function pad2(number){
    number = (number < 10 ? "0" : "") + number;
    number = number.substring(0, 2);
    return number;
};

export default function helper(){};