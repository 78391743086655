import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import pjson from "../package.json";
import { useUserContext } from "./lib/user_context";
// import {Button} from '@mui/material';

export default function Copyright() {
  const { apiVersion } = useUserContext();
  const [apiver, setApiver] = useState("1.8.30");


  useEffect(() => {
    setApiver(apiVersion);
    setApiver(apiVersion);
  }, [apiVersion]);

  const commonStyles = {
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    display: 'inline-flex',
    alignItems: 'center'
  };

  
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ padding: '10px 0' }}>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="textSecondary" style={commonStyles}>
          {'Copyright © '}
          DirectIT
          {' '}
          {new Date().getFullYear()}
          {'. ver'}
          {pjson.version}
          {' APIs ver'}
          {apiver}
        </Typography>        
      </Grid>
    </Grid>
  );
}
