import * as React from 'react';

function Pin({pin_icon}) {

    return(
       <div style={{display: "flex", justifyContent: "center"}}>
           <img
             src={pin_icon}
          />  
        </div>);
}

export default React.memo(Pin);