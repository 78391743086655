import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = (props) => (
    <ReactHelmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
    <header>
    
     <script async src="https://www.googletagmanager.com/gtag/js?id=G-D3MGH8PHHL"></script> 
     <script>{` window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-D3MGH8PHHL'); `}</script>
    </header>
    {/* <body>
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQ8TH9D" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
    </body> */}
    {/* <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQ8TH9D" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript> */}
    </ReactHelmet>
);

export default Helmet;