import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import bgImage from './orderDetail_backgroundImg.png';
import './pageBanner.css';


const PageBanner = props => {

    return (
        <div className='page-banner'>
            <h1 className='title'>{props.title}</h1>
            <h2 className='subheading'>{props.subheading}</h2>
        </div>
    );

};
export default PageBanner;
