import React, { useState }  from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import styles from './styles.js';

/*
function TextInput(props) {

    function renderLabel() {
            return (
                <Grid item md={props.labelSize} style={props.color === 'secondary'?styles.labelWrapperSecondary:styles.labelWrapper}>
                    <Typography variant = 'subtitle1' style={props.color === 'secondary'?styles.labelSecondary:styles.label}>{props.label}</Typography>
                </Grid>
            );
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            style={styles.root}
            spacing={0}
            >            
            {renderLabel()}
            <Grid item md={12 - props.labelSize}>
                <TextField 
                    editable={false}
                    variant="outlined"
                    size="small"
                    style={styles.formControlTextfield}
                    fullWidth
                    {...props}
                    label= {props.labelSize === 0? props.label:''}
                />
            </Grid>
        </Grid>
    );
}

export default TextInput;
*/



function TextInput(props) {

    function renderLabel() {
            return (
                <Grid item xs={props.labelSize} className={"fieldLabel"} style={props.color === 'secondary'?styles.labelWrapperSecondary:styles.labelWrapper}>                 
                    <Typography variant = 'subtitle1' style={props.color === 'secondary'?styles.labelSecondary:styles.label}>{props.label}</Typography>                  
                </Grid>
            );
    }

    return (
        <>
        {props.labelSize>0 && <Grid
            container
            direction="row"
            justifyContent="stretch"
            alignItems="stretch"
            style={styles.TextInputGrid}
            spacing={0}
            >
            {props.labelSize&&props.labelSize>0 && renderLabel()}
            <Grid item xs={12 - props.labelSize}>
                <TextField
                    editable={false}
                    variant={props.variant?props.variant:"outlined"}
                    size="small"
                    style={styles.formControlTextfield}
                    fullWidth
                    margin='none'
                    {...props}
                    label=''
                />
            </Grid>
        </Grid>
        }


        {props.labelSize==0 &&
            <Grid
            container
            direction="row"
            justifyContent="stretch"
            alignItems="stretch"
            style={styles.root}
            spacing={0}
            >
                <TextField
                    editable={false}
                    variant={props.variant?props.variant:"outlined"}
                    size="small"
                    style={styles.formControlTextfield}
                    fullWidth
                    margin='none'
                    {...props}
                    label={props.label}
                />
            </Grid>
        }
        </>
    );
}

export default TextInput;

