import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from "./lib/user_context";

import ReHelmet from "./Helmet";

ReactDOM.render(
    <>
        <ReHelmet title="DirectIt Tracking" />
        <UserProvider>
          <App />
        </UserProvider>
    </>,
    document.getElementById('root')
);


reportWebVitals();
