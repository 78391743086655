import React, {useEffect, useContext, useState } from "react"
import HttpRequest from "../HttpService";
import axios from "axios";

export const UserProvider = ({ children }) => {

  const [sessionIP, setSessionIP] = useState();
  const [apiVersion, setAPIVersion] = useState("1.1.0");

  useEffect(() => {

    console.log("UserProvider App url is", window.location.href);
  
    HttpRequest.getAPIVersion().then(async(body)=> {
      try{
        console.log("getAPIVersion:" + body.data.message);      
        setAPIVersion(body.data.message.substring(body.data.message.indexOf(":") + 1));
      }
      catch(e){console.log("getAPIVersion exception  "+ e )}
    });

    axios.get('https://api.ipify.org/').then((res)=> {
      //console.log(res.data);
      //localStorage.setItem("ipaddress",res.data);
      updateIPAddress(res.data);
    });   
  }, []);



  const updateIPAddress = (val) => {
    setSessionIP(val)
  }

  const getIPAddress = () => {
    return sessionIP;
  }

  return (
    <UserContext.Provider value={
      {  
        sessionIP,      
        apiVersion,       
        updateIPAddress,
        getIPAddress
      }
    }>
      {children}
    </UserContext.Provider>
  )
}

const UserContext = React.createContext();
// make sure use
export const useUserContext = () => {
  return useContext(UserContext)
}
