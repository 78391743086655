import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Link from '@material-ui/core/Link';
import { palette } from './palette';
import { withStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';
import logo from './logo.svg';
import 'animate.css/animate.css';
import {NavLink} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const MNavLink = withStyles({
    root: {
        color: (props) => props.selected ? 'black' : 'grey',
        textDecoration: 'none',
        '&:hover': {
            color: 'black',
            textDecoration: 'none',
        }
    }
})(Link);


// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    logoButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'block',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
        display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
        display: 'none',
        },
    },
}));

export default function NavigationBar(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        console.log("handleProfileMenuOpen");
        setAnchorEl(event.currentTarget);
       // window.location.href='/preferences';    
        navigate('/preferences');

    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {       
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const pathname = useLocation().pathname;
    console.log("NavigationBar", pathname);
    if(!pathname.includes("orderDetail") && !pathname.includes("history"))
        localStorage.setItem('searchHistory', 'no'); 


    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >     
        <MenuItem>
            <MNavLink href="/tracking" selected={pathname === '/tracking'}>
               Tracking
            </MNavLink>
        </MenuItem>
      
    </Menu>
  );

  return (
    <div className={classes.grow}>
        <AppBar position="sticky" style={{background: palette.greys.dark}}>
            <Toolbar>
                <IconButton
                edge="start"
                className={classes.logoButton}
                color="inherit"
                href="/#"
                >
                    <img src={logo} alt="Direct IT"/>
                </IconButton>                            
               
                <div className={classes.sectionDesktop}>
                    <NavLink to="/tracking" selected={pathname === '/tracking'}>
                        Tracking
                    </NavLink>
                </div>             
            </Toolbar>
        </AppBar>
        {renderMobileMenu} 
    </div>
  );
}
