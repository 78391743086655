import * as React from 'react';
import { useState,useEffect} from 'react';
// import { useParams } from "react-router-dom";
import {Container} from "@material-ui/core";
import PageBanner from "./components/pageBanner";
import Copyright from "../Copyright";
import "./orderDetail.css";



export default function NotFound() {    
  //const { trackingType } = useParams();
  const [title, setTitle] = useState();
  useEffect(() => {   
      console.log("=================NotFound==============================")
      setTitle("tracking number is not found")
  }, []);
  

  return (
    <div>
    <Container maxWidth="lg" class="NewOrder">
        <div id="bannerBackground">&nbsp;</div>
        <PageBanner title={title} />   
        <div id="pageContent"></div>  
    </Container>
    <footer>  <Copyright /> </footer> 
    </div>
  );
}
