import { palette } from "./palette";
import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: palette,
    typography: {
        // fontFamily: 'Open Sans',
        // The default font size of the Material Specification.
        fontSize: 14, // px
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        // Tell Material-UI what's the font-size on the html element.
        // 16px is the default font-size used by browsers.
        htmlFontSize: 16,
        subtitle1: {
            fontSize: 15,
            fontWeight: 550,
        },
        h6: {
            fontSize: 20,
            fontWeight: 550,
        }
    },
    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The default props to change
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
    overrides: {
        MuiListItem: {
            root: {
                color: palette.greys.medium,
            },
            button: {
                color: palette.greys.medium,
                "&:hover": {
                    backgroundColor: palette.greys.light,
                    color: palette.greys.medium,
                },
                "&$selected": {
                    backgroundColor: palette.primary.main,
                    color: palette.greys.white,
                },
                "&$selected:hover": {
                    backgroundColor: palette.primary.main,
                    color: palette.greys.white,
                },
            },
        },
        MuiAccordion: {
            root: {
                border: '1px solid rgba(0, 0, 0, .125)',
                boxShadow: 'none',
                borderRight: 0,
                borderLeft: 0,
                '&:before': {
                  display: 'none',
                },
                '&$expanded': {
                  margin: 'auto',
                },
            },
            expanded: {

            },
        },
        MuiAccordionSummary: {
            root: {
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
                marginBottom: -1,
                minHeight: 56,
                '&$expanded': {
                  minHeight: 56,
                },
                fontWeight: 'normal',
                color: '#337AB7',
            },
            content: {
                '&$expanded': {
                    margin: '12px 0',
                    fontWeight: 500
                },
            },
            expanded: {
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: 10,
            },
        },
        MuiButton: {
            containedPrimary: {
                color: palette.primary.contrastText,
                backgroundColor: palette.primary.main,
                '&:hover': {
                  backgroundColor: palette.primary.dark,
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                    backgroundColor: palette.primary.main,
                  },
                },
                '&:disabled': {
                    backgroundColor: palette.primary.muted,
                    color: palette.primary.contrastText,
                    opacity: 0.75,

                }
            },
            /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
            containedSecondary: {
                color: palette.secondary.contrastText,
                backgroundColor: palette.secondary.main,
                '&:hover': {
                    backgroundColor: palette.secondary.dark,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                    backgroundColor: palette.secondary.main,
                    },
                },
                '&:disabled': {
                    backgroundColor: palette.secondary.light,
                    color: palette.secondary.contrastText,
                    opacity: 0.75,

                }
            },
        }

    },
});